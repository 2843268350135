import template from './sidebar.html';
import style from './sidebar.less';

const Sidebar = angular.module('eventix.sidebar', [])
    .controller('SidebarController', function($translate, $window, roles, user, events, isAdmin, Company, Order, Event, Ticket,
        User, Shop, $q, $state, company, Locales, isBeta) {
        var vm = this;
        vm.company = company;
        vm.user = user;
        vm.helpCenter = HELP_CENTER_URL;
        vm.wlShowMarketPlace = SHOW_MARKETPLACE;
        vm.wlConnectionsUrl = !!CONNECTIONS_URL && !!company.guid ? CONNECTIONS_URL.replace(':company_id', company.guid) : null;
        vm.events = events;
        vm.isAdmin = isAdmin;
        vm.trueAdmin = _.find(roles, { name: 'Admin' });
        vm.companyAdmin = _.find(roles, { name: 'Company Admin' }) || vm.trueAdmin;
        vm.gotoPage = {
            search: '',
            type: 'auto'
        };
        vm.locales = Locales;

        vm.showMarketPlace = false;
        if(vm.wlShowMarketPlace){
            vm.showMarketPlace = true;
        }

        vm.accessToken = $window.auth ? $window.auth.getAccessToken().access_token : null;

        vm.copyBearer = function () {
            var textBox = document.getElementById("bearer");
            if (navigator.clipboard) {
                navigator.clipboard.writeText(vm.accessToken);
            } else {
                textBox.select();
                document.execCommand("copy");
            }
        };

        vm.searchPage = function(type) {
            switch(type) {
                case 'Company':
                    return Company.get({ guid: vm.gotoPage.search })
                        .then(company => {
                            return $state.go('eventix.dashboard.home', { as: company.guid }, { reload: true });
                        });
                case 'Event':
                    return Event.get({ guid: vm.gotoPage.search })
                        .then(event => {
                            return $state.go(
                                'eventix.dashboard.wizard',
                                { guid: event.guid, as: event.company_id },
                                { reload: true }
                            );
                        });
                case 'Ticket':
                    return Ticket.get({ guid: vm.gotoPage.search })
                        .then(ticket => {
                            return ticket.$queryProduct()
                                .then(products => $q.all(_.map(products, p => p.$queryEventDate())))
                                .then(eventDates => {
                                    eventDates = _.flatten(eventDates);
                                    if(!eventDates.length) {
                                        return $state.go('eventix.dashboard.home', {
                                            as: ticket.company_id
                                        }, { reload: true });
                                    }
                                    return $state.go(
                                        'eventix.dashboard.wizard',
                                        { guid: eventDates[0].event_id, as: ticket.company_id },
                                        { reload: true }
                                    );
                                });
                        });
                case 'User':
                    return User.get({ guid: vm.gotoPage.search })
                        .then(user => {
                            return $state.go(
                                'eventix.dashboard.company.users',
                                { as: user.company_id, editing: user.guid },
                                { reload: true }
                            );
                        });
                case 'Order':
                    return Order.get({ guid: vm.gotoPage.search })
                        .then(order => {
                            return Shop.get({ guid: order.shop_id })
                                .then(shop => {
                                    return $state.go(
                                        'eventix.dashboard.orders.edit',
                                        { as: shop.company_id, orderGuid: order.guid },
                                        { reload: true }
                                    );
                                });
                        });
                case 'Shop':
                    return Shop.get({ guid: vm.gotoPage.search })
                        .then(shop => {
                            return $state.go(
                                'eventix.dashboard.home',
                                { as: shop.company_id },
                                { reload: true }
                            );
                        });
                default:
                    return vm.searchPage('Company')
                        .catch(() => vm.searchPage('Event'))
                        .catch(() => vm.searchPage('Order'))
                        .catch(() => vm.searchPage('Shop'))
                        .catch(() => vm.searchPage('Ticket'))
                        .catch(() => vm.searchPage('User'));
            }
        };

        vm.setLocale = ($event, code) => {
            $($event.currentTarget).parents('ul,li').removeClass('active opened');

            if ($(window).width() < 768)
                $('#main-menu').slideUp();

            $translate.use(code);
        };
    })
    .directive('activeLinks', function($transitions, $state) {
        return {
            restrict: 'A',
            priority: 10,
            link: function(scope,el) {
                scope.stateLinks = $(el).find('a[ui-sref]');
                let deregister = $transitions.onSuccess({}, function(trans) {
                    let toState = trans.to();
                    toggleActive(scope.stateLinks.filter(`[ui-sref="${toState.name}"]`));
                });
                scope.$on('$destroy', deregister);
                toggleActive(scope.stateLinks.filter(`[ui-sref="${$state.current.name}"]`));

                function toggleActive(anchor) {
                    scope.stateLinks.parents('ul,li').removeClass('active opened');
                    anchor.parent().addClass('active');
                    anchor.parents('li.has-sub>ul').addClass('active opened');
                    anchor.parents('li.has-sub').addClass('opened');
                    scope.stateLinks.parents('ul.sub:not(.opened)');
                }
            }
        };
    })
    .directive('filterUnauthorizedStates', function(User, $state, Role) {
        return {
            restrict: 'A',
            link: function(scope, el) {
                el.find('[ui-sref]').each((i, el) => {
                    let $el = angular.element(el);
                    let stateName = $el.attr('ui-sref');
                    let state = $state.get(stateName);

                    if(!_.has(state, 'data.requiredRoles'))
                        return;

                    let authorized = _.find(state.data.requiredRoles, role => {
                        return Role.isAuthorizedAs(role);
                    });

                    if(!authorized) {
                        $el.parent('li').addClass('hidden');

                        return;
                    }

                    if(_.includes(state.data.requiredRoles, 'Beta'))
                        $el.parent('li').addClass('beta-feature');

                    if(_.includes(state.data.requiredRoles, 'Admin'))
                        $el.parent('li').addClass('admin-only');
                    else if(_.includes(state.data.requiredRoles, 'Whitelabel Admin'))
                        $el.parent('li').addClass('whitelabel-admin-only');
                });

                scope.$applyAsync(() => {
                    el.find('[register-submenu]').each((i, el) => {
                        let $el = angular.element(el);
                        let visibleSubItems = $el.find('li:not(.hidden)');

                        if(!visibleSubItems.length)
                            $el.addClass('hidden');
                    });
                });
            }
        };
    })
    .directive('sidebarToggle', function($transitions) {
        /**
         * Attach this directive to a button which toggles the sidebar. It'll
         * also attach a handler to window resize event to make the template
         * responsive.
         */
        return {
            restrict: 'A',
            link: function(scope, el, attr) {
                $(el).click(toggle);

                $transitions.onSuccess({}, function() {
                    if ($(window).width() < 768)
                        $('#main-menu').slideUp();
                });

                function toggle() {
                    if ($(window).width() >= 768) {
                        $('.page-container').toggleClass('sidebar-collapsed');
                        return;
                    }
                    $('.page-container').removeClass('sidebar-collapsed');
                    $('#main-menu').slideToggle();
                }
            }
        };
    })
    .directive('sidemenu',function() {
        return {
            restrict: 'A',
            link: function(scope, el, attrs) {
                el.find('>li').addClass('root-level');

                let lastWidth = $(window).width();
                $(window).on('resize', _.debounce(resize,300));
                resize();

                function resize() {
                    let width = $(window).width();
                    if (width > 1080 || width < 768)
                        $('.page-container').removeClass('sidebar-collapsed');
                    else
                        $('.page-container').addClass('sidebar-collapsed');

                    if(width >= 768)
                        $('#main-menu').show();
                    else if (!$('#main-menu').is(':visible') || lastWidth > width ) {
                        $('#main-menu').hide();
                    }
                    lastWidth = width;
                }
            }
        };
    })
    .directive('registerSubmenu', function() {
        return {
            restrict: 'A',
            link: function(scope,el,attrs) {
                el.find('>ul').addClass('sub');
                el.addClass('has-sub').find('>a').click(function() {
                    $(this).parents('li').toggleClass('opened');
                });
            }
        };
    });

export default {
    module: Sidebar.name,
    controller: 'SidebarController',
    templateUrl: template
};
